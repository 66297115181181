import React from "react"
import { useIntl } from "gatsby-plugin-react-intl"
import { AnimatedSlideIn } from "../../components/animatedSlideIn/animatedSlideIn"
import { StaticImage } from "gatsby-plugin-image"
import "./whatsColba.scss"
export const WhatsColba = () => {
    const intl = useIntl()
    return (
        <div id="whatsColba">
            <AnimatedSlideIn>
                <div className={"contained"}>
                    <div className="imageContainer">
                        <StaticImage
                            src="../../assets/images/illustration.svg"
                            alt="whatsColba"
                        />
                    </div>
                    <div className="textContainer">
                        <span className={"introTitle"}>
                            {intl.formatMessage({ id: "WHATS-COLBA-HEADER" })}
                        </span>
                        <h2 className={"title"}>
                            {intl.formatMessage({ id: "WHATS-COLBA-TITLE" })}
                        </h2>
                        <div>
                            <p>
                                {intl.formatMessage({
                                    id: "WHATS-COLBA-P1",
                                })}
                            </p>
                            <p>
                                {intl.formatMessage({
                                    id: "WHATS-COLBA-P2",
                                })}
                            </p>
                        </div>
                    </div>
                </div>
            </AnimatedSlideIn>
        </div>
    )
}
