import React from "react"
import { useIntl } from "gatsby-plugin-react-intl"
import "./customer.scss"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"
import { AnimatedSlideIn } from "../../components/animatedSlideIn/animatedSlideIn"

export const Customers = () => {
    const intl = useIntl()
    return (
        <div id="customerBloc">
            <div className={"container"}>
                <AnimatedSlideIn>
                    <div className={"contained"}>
                        <h2>{intl.formatMessage({ id: "CUSTOMERS_TITLE" })}</h2>
                        <h3>
                            {intl.formatMessage({ id: "CUSTOMERS_SUBTITLE" })}
                        </h3>
                        <div className={"logo-company"}>
                            <a href="https://fit-ebike.com/" target="_blank">
                                <StaticImage
                                    width={270}
                                    src={
                                        "../../assets/images/customers/biketec.png"
                                    }
                                    alt={"BikeTec AG"}
                                    layout="fixed"
                                />
                            </a>
                            <a href="https://fruss.com/" target="_blank">
                                <StaticImage
                                    width={270}
                                    src={
                                        "../../assets/images/customers/fruss.png"
                                    }
                                    alt={"Fruss AG"}
                                    layout="fixed"
                                />
                            </a>
                            <a href="https://lyyna.com/" target="_blank">
                                <StaticImage
                                    width={270}
                                    src={
                                        "../../assets/images/customers/lyyna.png"
                                    }
                                    alt={"Lyyna AG"}
                                    layout="fixed"
                                />
                            </a>

                            <a
                                href="https://www.samsaidyes.com/"
                                target="_blank"
                            >
                                <StaticImage
                                    width={270}
                                    src={
                                        "../../assets/images/customers/ssy.png"
                                    }
                                    alt={"SamSaidYes"}
                                    layout="fixed"
                                />
                            </a>
                            <a href="https://www.vlot.ch/" target="_blank">
                                <StaticImage
                                    width={270}
                                    src={
                                        "../../assets/images/customers/vlot.png"
                                    }
                                    alt={"Vlot AG"}
                                    className="justify-center m-auto h-24"
                                    layout="fixed"
                                />
                            </a>
                            <a
                                href="https://www.oxygenatwork.org/"
                                target="_blank"
                            >
                                <StaticImage
                                    width={270}
                                    src={
                                        "../../assets/images/customers/oxyge.png"
                                    }
                                    alt={"OxygenAtWork"}
                                    layout="fixed"
                                />
                            </a>
                        </div>
                    </div>
                </AnimatedSlideIn>
            </div>
        </div>
    )
}
