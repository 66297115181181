import * as React from "react"
import { MainLayout } from "../components/mainLayout/mainLayout"
import { SEO } from "../components/seo/seo"
import { Hero } from "../components/hero/hero"
import { WhatsColba } from "../blocs/whatsColba/whatsColba"
import { Customers } from "../blocs/customers/customers"
import "./index.scss"
import { ItemList } from "../components/itemList/itemList"
import { useIntl } from "gatsby-plugin-react-intl"
const IndexPage = () => {
    const intl = useIntl()
    const ourVisionItems = [
        {
            image: "vision01",
            text: "OUR-VISION-QUALIFICATION",
        },
        {
            image: "vision02",
            text: "OUR-VISION-CUSTOMER-NEEDS",
        },
        {
            image: "vision03",
            text: "OUR-VISION-SETTINGS",
        },
        {
            image: "vision04",
            text: "OUR-VISION-PHONE",
        },
        {
            image: "vision05",
            text: "OUR-VISION-PEOPLE",
        },
        {
            image: "vision06",
            text: "OUR-VISION-COG",
        },
        {
            image: "vision07",
            text: "OUR-VISION-RESET",
        },
    ]
    const ourServicesItems = [
        {
            image: "services01",
            title: "OUR-SERVICES-1",
            text: "OUR-SERVICES-1-SUBTEXT",
        },
        {
            image: "services02",
            title: "OUR-SERVICES-2",
            text: "OUR-SERVICES-2-SUBTEXT",
        },
        {
            image: "services03",
            title: "OUR-SERVICES-3",
            text: "OUR-SERVICES-3-SUBTEXT",
        },
        {
            image: "services04",
            title: "OUR-SERVICES-4",
            text: "OUR-SERVICES-4-SUBTEXT",
        },
        {
            image: "services05",
            title: "OUR-SERVICES-5",
            text: "OUR-SERVICES-5-SUBTEXT",
        },
        {
            image: "services06",
            title: "OUR-SERVICES-6",
            text: "OUR-SERVICES-6-SUBTEXT",
        },
    ]
    return (
        <MainLayout>
            <SEO title="Home" />
            <Hero />
            <WhatsColba />
            <ItemList
                backgroundColor="rgb(250, 250, 250)"
                values={ourVisionItems}
                subtitle={intl.formatMessage({ id: "OUR-VISION-TITLE" })}
                title={intl.formatMessage({ id: "OUR-VISION-INTRO" })}
                description={intl.formatMessage({ id: "OUR-VISION-TEXT" })}
            />
            <Customers />
            <ItemList
                values={ourServicesItems}
                subtitle={intl.formatMessage({ id: "OUR-SERVICES-TITLE" })}
                title={intl.formatMessage({ id: "OUR-SERVICES-INTRO" })}
                description={intl.formatMessage({ id: "OUR-SERVICES-TEXT" })}
            />
        </MainLayout>
    )
}

export default IndexPage
