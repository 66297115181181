import React from "react"
import { useIntl } from "gatsby-plugin-react-intl"
import { AnimatedSlideIn } from "../animatedSlideIn/animatedSlideIn"
import { graphql, useStaticQuery } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import "./itemList.scss"
export const ItemList = ({
    values,
    title,
    subtitle,
    description,
    backgroundColor = "#FFF",
}) => {
    const intl = useIntl()

    const data = useStaticQuery(graphql`
        fragment emojiFragmentSmall on File {
            childImageSharp {
                gatsbyImageData(
                    layout: FIXED
                    placeholder: BLURRED
                    formats: [AUTO, WEBP]
                )
            }
        }
        query {
            vision01: file(relativePath: { eq: "vision/badge.png" }) {
                ...emojiFragmentSmall
            }
            vision02: file(relativePath: { eq: "vision/hand.png" }) {
                ...emojiFragmentSmall
            }
            vision03: file(relativePath: { eq: "vision/settings.png" }) {
                ...emojiFragmentSmall
            }
            vision04: file(relativePath: { eq: "vision/phone.png" }) {
                ...emojiFragmentSmall
            }
            vision05: file(relativePath: { eq: "vision/people.png" }) {
                ...emojiFragmentSmall
            }
            vision06: file(relativePath: { eq: "vision/cog.png" }) {
                ...emojiFragmentSmall
            }
            vision07: file(relativePath: { eq: "vision/refresh.png" }) {
                ...emojiFragmentSmall
            }
            services01: file(relativePath: { eq: "services/1.png" }) {
                ...emojiFragmentSmall
            }
            services02: file(relativePath: { eq: "services/2.png" }) {
                ...emojiFragmentSmall
            }
            services03: file(relativePath: { eq: "services/3.png" }) {
                ...emojiFragmentSmall
            }
            services04: file(relativePath: { eq: "services/4.png" }) {
                ...emojiFragmentSmall
            }
            services05: file(relativePath: { eq: "services/5.png" }) {
                ...emojiFragmentSmall
            }
            services06: file(relativePath: { eq: "services/6.png" }) {
                ...emojiFragmentSmall
            }
        }
    `)

    return (
        <div className="itemList" style={{ backgroundColor: backgroundColor }}>
            <AnimatedSlideIn>
                <div className={"contained"}>
                    <div className={"textBlock centered"}>
                        <span>{title}</span>
                        <h2>{subtitle}</h2>
                        <p>{description}</p>
                    </div>
                    <div className={"valuesContainer"}>
                        {values.map((item) => {
                            return (
                                <div className="value" key={item.text}>
                                    <GatsbyImage
                                        alt="vision"
                                        image={
                                            data[item.image].childImageSharp
                                                .gatsbyImageData
                                        }
                                    />
                                    {item.title != null && (
                                        <h4>
                                            {intl.formatMessage({
                                                id: item.title,
                                            })}
                                        </h4>
                                    )}
                                    <p>
                                        {intl.formatMessage({
                                            id: item.text,
                                        })}
                                    </p>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </AnimatedSlideIn>
        </div>
    )
}
