import React from "react"
import { useIntl } from "gatsby-plugin-react-intl"
import { AnimatedSlideIn } from "../../components/animatedSlideIn/animatedSlideIn"
import "./hero.scss"
export const Hero = () => {
    const intl = useIntl()
    return (
        <div id="homeHero">
            <div className={"contained"}>
                <div className={"taglineContainer"}>
                    <div className={"text"}>
                        <AnimatedSlideIn>
                            <p>
                                {intl.formatMessage({ id: "HOME_EXPLANATION" })}
                            </p>
                            <h1>{intl.formatMessage({ id: "HOME_H1" })}</h1>
                        </AnimatedSlideIn>
                    </div>
                </div>
            </div>
        </div>
    )
}
